$timing-function : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$nav-width-desktop : 375%;
$nav-width-mobile : 40em;
$nav-item-nb : 6;

input {
  float: left;
  opacity: 0;
}

span {
  margin-top: 5px;
}

label {


  color: #50C79B;
  float: right;
  margin-right: clamp(30px, -1%, 35px);
  z-index: 1000;

  padding-bottom: 13.5px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  @media (max-width: 35em) {
    margin-right: clamp(5px, -0.5%, 10px);
    width: 10%;
    padding-top: 15px;
    padding-bottom: 25px;
  }

  &::before {
    float: right;
    z-index: 1;
    bottom: 0;
    background-color: rgba(darken(#3498db, 40%), .75);
    content: '';
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s $timing-function;
  }

  .burger {

    float: right;
    z-index: 3;
    width: 1.95em;
    height: 1.55em;
    margin: 0;
    padding: 0;
    transition: opacity .5s $timing-function;


    @media (max-width: 35em) {
      width: 1.80em;
      height: 1.05em;
      margin-top: 5%;
    }

    &::before,
    .bar,
    &::after {
      float: right;
      display: block;
      width: 100%;
      height: 12%;
      margin: 5px;
      background: #5cd1c1;
      content: '';
      transition: all .5s $timing-function;

      @media (max-width: 375px) {
        margin: 3px;
        margin-top: 3px;
        margin-bottom: 3px;
        height: 20%;
      }
    }

    .bar {
      top: 44%;
    }

    &::before {
      top: 0;
      transform-origin: top left;
    }

    &::after {
      bottom: 0;
      transform-origin: bottom left;
    }
  }
}

input:focus+label,
label:hover {
  .burger {
    opacity: .75;
  }
}

nav {
  color: white;
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  transform: translate3d(0, 0, 0);
  /* material acceleration */
  transform: translateX(-100%);
  will-change: transform;
  transition: transform .5s $timing-function;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    z-index: -1;
    width: $nav-width-desktop;
    background: #5cd1c1;
    content: '';
    transform: skewX(-30deg) translateX(0%);
    transform-origin: bottom left;
    will-change: transform;
    transition: transform .5s $timing-function;

    @media(max-width: 35em) {
      width: 100%;
      left: 0;
      transform: unset;
      transform-origin: unset;
      will-change: unset;
      transition: unset;
      display: flex;
      align-items: center;
    }

    @media(max-width: 25em) {
      width: 100%;
      left: 0;
      transform: unset;
      transform-origin: unset;
      will-change: unset;
      transition: unset;
      display: flex;
      align-items: center;
    }
  }

  .nav-link {
    display: none;
    margin: .5em 0;
    padding: .2em 1em;
    font-size: 1.5em;
    color: white;
    text-decoration: none;
    font-weight: 1000;
    transform: translateX(0%);
    transition: color .15s, transform .5s;
    transition-timing-function: $timing-function;

    @media only screen and(min-width: 1366px) and (min-height: 1024px) {
      padding: 0.3em .5em;
      font-size: 2em;

    }

    @media only screen and(min-width: 1024px) and (min-height: 1366px) {
      padding: 0;
      font-size: 2em;

    }

    @for $i from 1 through $nav-item-nb {
      &:nth-child(#{$i}) {
        transition-delay: 0s,
        #{50 + (50 * $i)}ms;
      }
    }

    &:hover,
    &:focus {
      text-decoration: underline;
      text-underline-position: under;
    }
  }
}

main {
  overflow: hidden;

  .content {
    transform: translate3d(0, 0, 0);
    /* material acceleration */
    will-change: transform, filter;
    transition: all .5s $timing-function;
  }
}

[id="navcheck"]:checked {
  &+label {
    &::before {
      opacity: 1;
      pointer-events: auto;

      @media(max-width: 35em) {
        width: 100%;
        left: 0;
        transform: unset;
        transform-origin: unset;
        will-change: unset;
        transition: unset;
        display: flex;
        align-items: center;
      }

      @media(max-width: 25em) {
        width: 100%;
        left: 0;
        transform: unset;
        transform-origin: unset;
        will-change: unset;
        transition: unset;
        display: flex;
        align-items: center;
      }
    }

    .burger {

      &::before,
      &::after {
        width: 141.42%; // Pythagore!

        @media (max-width: 375px) {
          width: 100%;
        }
      }

      &::before {
        transform: rotate(-45deg) translateY(-75%);
        background: white;
        position: fixed;
        top: 50px;
        left: 95%;
        width: 3px;
        height: 30px;

        @media (max-width: 35em) {
          left: 90%;
        }


      }

      &::after {
        transform: rotate(45deg) translateY(75%);
        background: white;
        position: fixed;
        top: 7px;
        left: 95%;
        width: 3px;
        height: 30px;

        @media (max-width: 35em) {
          left: 90%;
        }
      }

      .bar {
        float: right;
        transform: scale(0.1);
      }
    }
  }

  &~nav {
    transform: translateX(0);

    @media(max-width: 35em) {
      width: 100%;
      left: 0;
      transform: unset;
      transform-origin: unset;
      will-change: unset;
      transition: unset;
      display: flex;
      align-items: center;
    }

    @media(max-width: 25em) {
      width: 100%;
      left: 0;
      transform: unset;
      transform-origin: unset;
      will-change: unset;
      transition: unset;
      display: flex;
      align-items: center;
    }

    &::before {
      transform: skewX(-15deg) translateX(-100%);
      display: block;
      width: 500px;



      @media (min-width: 1366px) and (min-height: 1024px) {
        transform: skewX(-15deg) translateX(-130%);
      }

      @media (min-width: 1024px) and (min-height: 1366px) {
        transform: skewX(-10deg) translateX(-130%);
        width: 330%;
      }


      @media(max-width: 35em) {
        width: 100%;
        left: 0;
        transform: unset;
        transform-origin: unset;
        will-change: unset;
        transition: unset;
        display: flex;
        align-items: center;
      }

      @media(max-width: 25em) {
        width: 100%;
        left: 0;
        transform: unset;
        transform-origin: unset;
        will-change: unset;
        transition: unset;
        display: flex;
        align-items: center;
      }



    }

    .icone-social {
      display: flex;
    }

    .nav-link {
      transform: translateX(-50%);
      display: block;
      margin-left: clamp(-150px, -100%, -175px);

      @media(max-width: 35em) {
        margin-left: 0;
        transform: unset;
      }

      @media (max-width: 360px) {
        margin-left: 0;
        transform: unset;
      }

      @media (max-width: 320px) {
        margin-left: 0;
        transform: unset;
      }

    }


  }

  &~main .content {
    transform: translateX(3em);
    transform-origin: left center;
    filter: blur(2px);
  }
}

/* helper */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* misc */

main {
  background: #fffaf2;
}

article {
  width: 80%;
  margin: 0 auto;
  font-size: 1.3em;

  @media(min-width: 60em) {
    width: 50%;
  }
}

h1 {
  font: 2.5em/1.4 "roboto condensed";
  font-weight: 700;
}


.logoContainer {
  padding-bottom: 30px;
  width: 100px;
  height: 35px;
  margin-left: clamp(30px, -1%, 35px);
  fill: #50C79B;
  display: flex;

  @media (max-width: 35em) {
    width: 75px;
    margin-top: clamp(30px, 1%, 35px);
    margin-right: 7px;
    margin-left: clamp(5px, -0.5%, 10px);
  }
}

.navTitle {
  vertical-align: middle;
  margin-top: clamp(2px, 5%, 5px);
  width: 30%;
  margin-left: 2%;
  margin-bottom: clamp(2px, 5%, 5px);

  @media (max-width: 35em) {
    width: 40%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navHeader {
  width: 100%;
  height: 30%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  background-color: white;

  @media (max-width: 35em) {
    height: clamp(100px, 19%, 115px);
  }

}

.logoSite {
  height: clamp(50px, 50%, 100px);
  width: clamp(50px, 50%, 100px);

  margin-top: clamp(-1px, -3%, -7px);
}

.align-center {
  align-items: center;
}