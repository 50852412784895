@import url("https://cdn.jsdelivr.net/npm/@tailwindcss/typography@0.2.x/dist/typography.min.css");

@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
  font-family: pompiere;
  src: url(./fonts/Pompiere-Regular.ttf);
  font-weight: bold;
}

@font-face {
  font-family: spinnaker;
  src: url(./fonts/Spinnaker-Regular.ttf);
  font-weight: bold;
}

@font-face {
  font-family: poppins;
  src: url(./fonts/Poppins-ExtraLight.ttf);
  font-weight:200;
}

@font-face {
  font-family: poppins-semibold;
  src: url(./fonts/Poppins-SemiBold.ttf);
  font-weight:600;
}

@font-face {
  font-family: poppins-italic;
  src: url(./fonts/Poppins-ExtraLightItalic.ttf);
  font-weight:200;
}

body {
  background: rgb(255, 250, 242);
  margin: 0;
  font-family: 'poppins';
  overflow-x: hidden;
  color: rgb(39, 39, 39);
  font: 1em/1.4 "poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

.test {
  width: 50%;
}

.testBowie {
  width: 100000px;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}

.removeMarginTop {
  margin-top: 0 !important;
}