.footer{
  left: 0;
  bottom: 0;
  width: 100%;
  height:70px;
  display: flex;
    align-items: center;
  background-color: #5cd1c1;
  color: white;
  text-align: center;
}

.footerText {
    width: 100%;
}
