div.gallery {
    border: none;
  }
  
  div.gallery:hover {
    border: none;
  }
  
 
  
  div.desc {
    padding: 15px;
    text-align: center;
    width: 20vw;

    @media only screen and (max-width:500px){
      width: 250px;
    }
  }
  
  * {
    box-sizing: border-box;
  }
  
  .responsive {
    padding: 0 6px;
    float: left;
    width: 30%;
    margin: 3% 1.5%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transform: translateX(0px) translateY(0px) translateZ(0px);
    transition: opacity 200ms ease 0s, transform 800ms ease 0s;
  
  }
  .overlay {
    position: absolute;
    top: 0;
    width: 30vw;
    height: 30vw;
    opacity: 0;
    transition: .5s ease;
    background-color: #0e0e0e96;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    text-align: center;
}
.gallery:hover .overlay {
  opacity: 1;
  cursor: pointer;
}
  // @media only screen and (max-width: 700px) {
  //   .responsive {
  //     width: 55%;
  //   }
  // }
  
  @media only screen and (max-width: 500px) {
    .responsive {
      width: 90%;
    }
  }
  
  

  .projectClass {
      margin-left: 3.5%;
      padding-top: 2%;
      overflow: hidden;

      @media (max-width:65em){
          padding-top: 5%;
      }
  }

  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .image-style{
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height: 30vw;
    background: #ffefd3;
    background-size: cover;
    background-position: center;
    @media only screen and (max-width:500px){
      width: 250px;
      height: 250px;
    }
   
    
  }

  .image-border {
    border-style: solid;
    border-color: white;
    &:hover{
      filter:opacity(90%) contrast(97%);
      cursor:pointer;
    }
  }

 