.modalClass {
position: fixed;
margin: auto;
top: 0;
left: 0;
z-index: 5000;
width: 100%; 
height: 100%; 
background-color: #fffaf2; 
justify-content: space-between;
align-items: center;
}

.arrow {
    padding: 5%;
    font-size: 3em;
    color: #50C79B;
    &:hover{
cursor: pointer;
    }
}

.galleryImage {

    &:hover{
        cursor: pointer;    
    }
    
}