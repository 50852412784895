.projectTitle {
    margin-left: 0;
    text-align: center;
    margin-top: 5%;
    font-size: 2.5em;
    color: #5cd1c1;
}

.projectMain {
    margin-bottom: 5%;
}

.projectList {
    display: block;
    margin-top: 5%;
    margin-left: 5%;
}

.listBullet {
    font-size: 1.5em;
}

.siteLink {
    color:  #5cd1c1;
    &:hover{color: #51bbad;}
}