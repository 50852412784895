.contactClass {
  
    @media (max-width: 65em) {
      display: block;
      margin: 0;
    }
  
    @media (max-width: 35em) {
      display: block;
      margin: 0;
    }
  
  }

  .contacts{
    margin-left: 7%;
    margin-top: 5%;

    @media (max-width: 65em) {
      display: block;
      margin-left: 0%;
    }
  
  }

  .contactSection {
      width: clamp(350px, 40%, 400px);
      border: none;
      color: white;
      
      padding: 2%;
      padding-top: 1%;
      margin: 2%;
      border-color: #5cd1c1;

      @media (max-width: 65em) {
        margin-left: 8%;
        width: 84%;
      }
  }

  // .contactTitle {
  //   color: #585858;
  // margin-left: 45%;
  //   font-size:2em ;
  //   margin-top: 2%;
  //   margin-bottom: 4%;

  //   @media (max-width: 35em) {
  //     margin-top: 6%;
  //     margin-bottom: 6%;
  //     margin-left: 30%;
  //   }
  // }

  .contactSocial {
    width: 2%;
  }

  .socialTitle{
    font-size: 1.3em;
  }

  .contactParagraph {
    width: 100%;
    height: 100px;
    padding-top: 5%;
    
  }

  .contactLogo {
    width: 80px;
  height: 35px;
  margin: 1%;
  margin-top: 4%;
  margin-left: clamp(10px, 5%, 15px);
  fill: white;
  &:hover{fill:rgb(238, 238, 238)};

  @media (max-width: 65em) {
    margin: 5%;
    width: 30px;
    height: 30px;
    margin-left: 3%;
  }
  @media (max-width: 30em) {
    margin: 5%;
    width: 25px;
    height: 25px;
    margin-left: 3%;
  }
  }

  .Email-Form {
    color:black;
    background-color:#fffaf2;
    text-align:left;
    padding-top:3%;
    padding-bottom:3%;
    width: 70%;
    margin-left: 10%;

    @media (max-width: 65em) {
width: 80%;      
    }
    }

    .Email-Form-Title {
    font-size:100px;
    }

    .contactInput {
      margin-left: 0;
      padding: 13px 17px;
      margin-bottom: clamp(20px, 5%, 25px);
      background-color: #86868628;
      width: 80%;

      @media (max-width: 65em) {
        width: 100%;
      }
    }

    .inputButton {
      margin-left: 0;
      background-color: #5cd1c1;
    color: white;
    padding: 1.1% 3%;
    border-radius: 3px;
    width: clamp(75px, 20%, 100px);
    text-align: center;

    @media (max-width: 35em) {
      padding: 2.1% 5%;
      margin-bottom: 5%;
    }
      &:hover{
        background-color: rgba(82, 82, 82, 0.692);
        color: white;
      }
    }

    .boxForm{
      margin-left: 0;
      padding: 13px 17px;
      margin-bottom: clamp(20px, 5%, 25px);
      background-color: #86868628;
      width: 80%;

      @media (max-width: 65em) {
        width: 100%;
      }
    }

    .socialBox{
      background-color:  #5cd1c1b9;
      padding: clamp(45px, 6%, 70px);
      border-radius: 1px;
      padding-left: 3%;

      @media (max-width: 65em) {
       padding-left: 10%;
       padding-top: 3%;
       padding-bottom: 12%;
      }
    }