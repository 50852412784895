.accessory {
  margin-left: 40%;
  overflow: visible;
  /* For IE */
  padding: 0;
  border: solid;
  color: #5cd1c1;
  text-align: center;
  width: 20%;

  @media (max-width: 30em) {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 60%;
    margin-left: 20%;
  }

  &::before {
    content: "\2022";
    display: inline-block;
    position: relative;
    top: -0.77em;
    font-size: 3em;
    padding: 0 0.25em;
    background: #fffaf2;

  }
}

.hrContainer {
  width: 25%;
  left: 50%;
}

.homeTitle {
  text-align: justify;
  font-size: 2em;
  margin-bottom: 10%;
}

.homeParagraph {
  width: 30%;
  margin-left: 37%;
  margin-right: 43%;
  text-align: left;
  font-size: 1.2em;
  margin-top: 5%;
  margin-bottom: 4%;
  color: #585858;

  @media (max-width: 65em) {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 50%;
    margin-left: 25%;
  }

  @media (max-width: 30em) {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 60%;
    margin-left: 20%;
  }
}

.homeClass {
  // padding-top: clamp(250px, 20%, 300px);

  // @media (max-width: 65em) {
  //   padding-top: clamp(190px, 15%, 200px);
  // }

  // @media (max-width: 35em) {
  //   padding-top: clamp(125px, 15%, 135px);
  // }

}

.paragraphSection {
  background-color: #5cd1c1b9;
  padding: 1.5%;
  margin-top: 10%;
}

.projectsButton {
  background-color: #5cd1c1;
  color: white;
  padding: 1.1% 3%;
  margin-left: 45%;
  border-radius: 3px;
  width: 10%;

  @media (max-width: 30em) {
    margin-left: 35%;
    padding: 2.1% 5%;
  }
}

.homeSpacer {
  background-image: url("../images/IMG_0126.PNG");
  background-size: cover;
  height: 300px;

  @media (max-width: 35em) {
    height: 145px;
  }
}

.spacerOverlay {
  height: 100%;
  width: 100%;
  background-color: #58585800;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  color: #5cd1c1;
  letter-spacing: 5px;
  font-family: poppins-semibold;

  @media (max-width: 35em) {
    font-size: 55px;
  }
}

.logo {
  width: 40px;
  height: 40px;
  margin: 1%;
  margin-top: 2%;
  margin-left: clamp(10px, 5%, 15px);
  fill: #585858;

  &:hover {
    fill: #ebebeb
  }

  @media (max-width: 65em) {
    margin: 5%;
    width: 40px;
    height: 40px;
    margin-left: 3%;
  }

  @media (max-width: 35em) {
    margin: 5%;
    width: 35px;
    height: 35px;
    margin-left: 3%;
  }
}

.icone-social {
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 35em) {
    margin-left: 0;
  }

}