.aboutPhoto {
    box-shadow: 15px 20px 10px #ababab6b;
    width: 49%;

    @media (max-width: 25em) {
        width: 90%;
        margin: auto;
      }
}

.aboutSection {
    display: flex; 
    justify-content: space-between;
     margin: 5% 14%;
min-width: 1000px;

     @media (max-width: 25em) {
        display: block;
        margin: auto;
        margin-top: 40px;
        min-width: 0px;
      }
}

.textSection {
    width: 49%;
     border-style: solid;
      border-width: 0px; 
      margin-left: 60px;

      @media (max-width: 25em) {
        width: 80%;
        border-style: solid;
        border-width: 0px;
        margin-left: 40px;
        margin-top: 50px;
        margin-bottom: 40px;
      }
}